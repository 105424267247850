import React from 'react';
import { useState } from "react";

import PropTypes from "prop-types";

import Nav from "./Nav"
import AnounceTopBanner from './AnounceTopBanner'
import CheckBanner from './RegistrationBanner';

const Header = ({ path }) => {
  return (
    <header>

      {
        // Don't show RegistrationBanner in the header of this
        // page because we are already showing it in the main section
        
        // path !== '/voter-registration/' && path !=='/' ? <CheckBanner type="banner"/> : ''
        // path !== '/voter-registration/' && path !== '/find-your-ward/' ? <CheckBanner type="banner" path={path} /> : ''
        // path !== '/postal/' ? <AnounceTopBanner type="banner" color="red"/> : ''
        //path !== '/voter-registration/' ? <AnounceTopBanner type="banner" color="red"/> : ''

      }
      
      {/* 
      TOP BANNER HIDING
      <AnounceTopBanner type="notification" color="primary" link_to="/voting" /> 
      */}

      <Nav />

    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
