import { graphql, Link } from 'gatsby';
import React, { useState } from 'react'
import Countdown from 'react-countdown';

import IconClose from "./icons/IconClose"

export default function AnounceTopBanner ({type = "anouncement", color, link_to}) {
  const [ bannerState, setBannerState ] = useState('initial');


  const hideBanner = e => {
    setBannerState('hide');
  }

  // = = = = = = = = 
  // Events Functions
  // Get Diff Date
  const getDaysDifference = (date) => {
    const presentDate = new Date();
    const electionDate = new Date(date);
    // Plus 1  to include the election date
    const daysDiff = Math.ceil((electionDate - presentDate) / (1000 * 3600 * 24)) + 1;
    return daysDiff;
  }

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {

    if (type === "notification") {
      return <>Learn more your candidates in our guide. <a href="/find-your-ward">Find your ward</a></>
    } else {
      if (completed) {
        // Render a completed state
        return <>Polls are now closed. <a href="/results">SEE RESULTS</a></>;
      } else {
        // Render a countdown
        return <span>Today is Election Day. Polls close at 8pm. You have {hours} hours, {minutes} minutes and {seconds} seconds left to vote.</span>;
      }
    }
  };

  
  return (
    <>
      <section className={`banner-wrapper ${bannerState === "initial" ? '' : 'd-none'}`}>

        <div className={`check-banner check-banner--${color}`}>

          <div className="check-banner__intro"/>

            {

                getDaysDifference('3/23/2022') < 1 ?
                  <p className="anounce-text">
                    <Countdown
                      date={'2022-03-24T20:00:00'}
                      renderer={renderer}
                    />
                  </p>
                :
                  <p className="anounce-text">Polling Day is just <span className="registration-date__number">{getDaysDifference('3/23/2022')}</span> {getDaysDifference('3/23/2022') === 1 ? 'Day' : 'days'} away. Make your plan to vote on 24th March.</p>

            }
          
            {/* <p className="anounce-text">Only <span className="registration-date__number">{getDaysDifference('3/9/2022')}</span> days left to apply for a postal vote. <Link to="/postal">Apply today</Link></p> */}
            {/* <p className="anounce-text" style={{color: "white"}}>The deadline for nominations to stand in the March 2022 elections has now passed.</p> */}
            <IconClose 
              bannerState={ bannerState }
              hideBanner = { hideBanner } 
            />
          <div className="check-banner__intro"/>
        </div>
      </section>
    </>
  )
}


// = = = = = = = = 
// GraphQL
export const query = graphql`
  query TopBannerQuery {
    electionsDate: markdownRemark(frontmatter: {date_id: {eq: "ward-elections"}}) {
      frontmatter {
        elections_date(formatString: "M/D/Y")
      }
    }
  }
`;